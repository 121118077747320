import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Global, css } from '@emotion/react'
import { Link, useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { useRecoilState } from 'recoil'
import { MdLanguage } from 'react-icons/md'
import format from 'date-fns/format'

import { Flex, Button, Stack, Divider } from '@chakra-ui/react'

import {
  Footer,
  FooterBody,
  FooterColumn,
  FooterDivider,
  FooterBottom,
  Main,
  P,
  BookingModal,
  WhatsappButton,
  Popup,
  Modal,
  H1,
  H2,
  Header,
  NavLink,
} from '../ui'

import SocialLinks from './socialLinks'
import FloatingBook from './floatingBook'
import FormQuote from './formQuote'

import { bookingModalState } from '../store'
import SEO from './seo'
import ButtonBook from './buttonBook'

import logoFooter from '../images/logo-full.png'
import logo from '../images/logo-full.png'

const globalStyles = css`
  @font-face {
    font-family: Roboto;
    font-style: normal;
    font-display: swap;
    font-weight: 300;
  }

  @font-face {
    font-family: Roboto;
    font-style: normal;
    font-display: swap;
    font-weight: 400;
  }

  @font-face {
    font-family: Roboto;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  * {
    font-size: 14px;
    font-family: Roboto, Arial, Helvetica, sans-serif !important;
    font-weight: 300;

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }

  p,
  span {
    font-size: 16px;
    max-height: 999999px;
    opacity: 0.85;
  }

  .react-datepicker-wrapper {
    width: 100% !important;
  }
`

function Layout({
  children,
  title,
  variantHeader,
  disableHeader,
  disableWhatsapp,
  disablePopup,
  disableFloatingBanner,
}) {
  const { t } = useTranslation()
  const { language, changeLanguage } = useI18next()
  const [bookingModal, setBookingModal] = useRecoilState(bookingModalState)

  const onSubmit = async (values, setSuccess) => {
    const body = {
      ...values,
      checkIn: format(values.checkIn, 'MMMM, dd, yyyy'),
      checkOut: format(values.checkOut, 'MMMM, dd, yyyy'),
      to: 'info@hotelmayansecret.com',
    }

    const res = await fetch(`${process.env.GATSBY_FORM_API}/api/reservation`, {
      headers: {
        Accept: 'application/json',
      },
      method: 'post',
      body: JSON.stringify(body),
    })

    if (res.ok) setSuccess(true)
    else setSuccess(false)
  }

  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={`${title}`} />

      {!disableHeader && (
        <Header
          logo={
            <Link to="/">
              <Flex height={{ base: '25px', sm: '40px' }} align="center">
                <img style={{ height: '100%', width: 'auto' }} src={logo} />
                <Flex marginLeft={3}>
                  <Button
                    size="sm"
                    variant="outline"
                    leftIcon={<MdLanguage />}
                    colorScheme="primary"
                    onClick={() => changeLanguage(language === 'es' ? 'en' : 'es')}
                    _hover={{ background: 'transparent' }}
                  >
                    {language === 'es' ? 'EN' : 'ES'}
                  </Button>
                </Flex>
              </Flex>
            </Link>
          }
          rightButton={<ButtonBook alignSelf="flex-end" />}
          variantHeader={variantHeader}
          backgroundColor="white"
        >
          <Link to="/suites">
            <NavLink fontSize="1rem">{t('common:nav.house')}</NavLink>
          </Link>
          <Link to="/weddings">
            <NavLink fontSize="1rem">{t('common:nav.weddings')}</NavLink>
          </Link>
          <Link to="/spa">
            <NavLink fontSize="1rem">{t('common:nav.spa')}</NavLink>
          </Link>
          <Link to="/events">
            <NavLink fontSize="1rem">{t('common:nav.events')}</NavLink>
          </Link>
          <Link to="/explore">
            <NavLink fontSize="1rem">{t('common:nav.experiences')}</NavLink>
          </Link>
          <Link to="/gallery">
            <NavLink fontSize="1rem">{t('common:nav.gallery')}</NavLink>
          </Link>
          <Link to="/contact">
            <NavLink fontSize="1rem">{t('common:nav.contact')}</NavLink>
          </Link>
        </Header>
      )}
      <Flex direction="column" minHeight="100vh">
        <Main>{children}</Main>
      </Flex>
      <Footer>
        <FooterBody>
          <FooterColumn>
            <Link to="/">
              <Flex width={{ base: '200px', md: '250px' }}>
                <img src={logoFooter} />
              </Flex>
            </Link>
            <P>{t('index:footer.desc')}</P>
            <Flex>
              <Button
                variant="outline"
                leftIcon={<MdLanguage />}
                colorScheme="primary"
                onClick={() => changeLanguage(language === 'es' ? 'en' : 'es')}
                _hover={{ background: 'transparent' }}
              >
                {language === 'es' ? 'Change to English' : 'Cambiar a Español'}
              </Button>
            </Flex>
          </FooterColumn>

          <FooterColumn />

          <FooterColumn title={t('index:footer.contact')}>
            <SocialLinks />
          </FooterColumn>
        </FooterBody>
        <FooterDivider backgroundColor="red.800" />
        <FooterBottom direction={{ base: 'column', md: 'row' }} justify="space-between">
          <P fontSize="sm" lineHeight="shorter" textAlign={{ base: 'center', lg: 'left' }}>
            {t('index:footer.terms')}
          </P>
        </FooterBottom>
      </Footer>

      {!disableWhatsapp && (
        <WhatsappButton
          bottom="65px"
          href={`https://api.whatsapp.com/send?phone=529831684124&text=${t('common:whatsappText')}`}
        />
      )}

      {!disableFloatingBanner && (
        <FloatingBook title="Mayan Secret Chetumal·Bacalar" desc={t('index:footer.floatingBook')} />
      )}

      {/*!disablePopup && (
        <Popup>
          <ButtonBook
            width="100%"
            height="100%"
            promotion="lastminute"
            arrival="2021-02-15"
            departure="2021-02-16"
          >
            <img style={{ width: '100%' }} src={language === 'es' ? popupEs : popupEn} />
          </ButtonBook>
        </Popup>
      )*/}

      {/* <Modal
        isOpen={bookingModal.isOpen}
        onClose={() => setBookingModal((obj) => ({ ...obj, isOpen: false }))}
      >
        <Stack maxWidth="560px" marginX="auto">
          <H1>{t('common:bookingModal.title')}</H1>
          <H2>{t('common:bookingModal.desc')}</H2>
          <Divider marginY={6} />
          <FormQuote
            hideOnSuccess
            onSubmit={() => {}}
            name={{
              label: t('forms:name.label'),
              required: t('forms:name.required'),
              invalid: t('forms:name.invalid'),
              placeholder: t('forms:name.placeholder'),
            }}
            email={{
              required: t('forms:email.required'),
              invalid: t('forms:email.invalid'),
              placeholder: t('forms:email.placeholder'),
            }}
            checkIn={{
              label: t('forms:checkIn.label'),
              required: t('forms:checkIn.required'),
              invalid: t('forms:checkIn.invalid'),
              placeholder: t('forms:checkIn.placeholder'),
            }}
            checkOut={{
              label: t('forms:checkOut.label'),
              required: t('forms:checkOut.required'),
              invalid: t('forms:checkOut.invalid'),
              placeholder: t('forms:checkOut.placeholder'),
            }}
            message={{
              label: t('forms:message.label'),
              required: t('forms:message.required'),
              invalid: t('forms:message.invalid'),
              placeholder: t('forms:message.placeholder'),
            }}
            successText={t('forms:messages.sent')}
            buttonText={t('forms:buttons.askQuote')}
          />
        </Stack>
      </Modal> */}

      <BookingModal
        isOpen={bookingModal.isOpen}
        onClose={() => setBookingModal((obj) => ({ ...obj, isOpen: false }))}
        url={`https://rbe.zaviaerp.com/${bookingModal.promotion}?hotel=mayansecret&arrival=${
          bookingModal.arrival
        }&departure=${bookingModal.departure}&lng=${language === 'es' ? 'es' : 'en'}&currency=${
          language === 'es' ? 'mxn' : 'usd'
        }`}
      />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  disableHeader: PropTypes.bool,
  disableWhatsapp: PropTypes.bool,
  disablePopup: PropTypes.bool,
  disableFloatingBanner: PropTypes.bool,
  title: PropTypes.string,
  variantHeader: PropTypes.oneOf(['solid', 'transparent']),
}

Layout.defaultProps = {
  disableHeader: false,
  disableWhatsapp: false,
  disablePopup: false,
  disableFloatingBanner: false,
  title: '',
}

export const query = graphql`
  fragment fixedImage on File {
    childImageSharp {
      fixed(width: 680, quality: 65) {
        ...GatsbyImageSharpFixed
      }
    }
  }

  fragment bannerSectionImage on File {
    childImageSharp {
      fluid(maxWidth: 1270, quality: 65) {
        ...GatsbyImageSharpFluid
      }
    }
  }

  fragment multipleFixedImage on FileConnection {
    edges {
      node {
        childImageSharp {
          fixed(width: 860, quality: 75) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`

export default Layout

import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from '@emotion/styled'
import { FaFacebook, FaInstagram, FaTripadvisor } from 'react-icons/fa'
import { Stack, IconButton, Button } from '@chakra-ui/react'

import { P } from '../ui'

const A = styled.a`
  font-size: inherit;
  text-decoration: underline;
`

const SocialLink = ({ icon, to }) => (
  <IconButton
    as="a"
    href={to}
    target="_blank"
    variant="outline"
    colorScheme="primary"
    _hover={{ background: 'transparent' }}
    icon={icon}
  />
)

function SocialLinks(props) {
  const { t } = useTranslation()

  return (
    <Stack spacing={2} {...props}>
      <P>
        Email: <A href="mailto:mayan.secret1@gmail.com">mayan.secret1@gmail.com</A>
      </P>
      <P>Tel: +52 (983) 168 4124</P>
      <P>
        Carretera Chetumal - Bacalar Km. 9.5, 77900, Q. Roo
        <Button
          as="a"
          colorScheme="primary"
          size="sm"
          variant="ghost"
          href="https://goo.gl/maps/okKhMAt6xxXKmjCB6"
          target="_blank"
          _hover={{ background: 'transparent' }}
        >
          {t('common:viewMap')}
        </Button>
      </P>
      <Stack isInline shouldWrapChildren>
        <SocialLink to="https://instagram.com/mayansecretmx" icon={<FaInstagram />} />
        <SocialLink to="https://www.facebook.com/mayansecretmx" icon={<FaFacebook />} />
        <SocialLink
          to="https://www.tripadvisor.com/Hotel_Review-g317139-d5294258-Reviews-Mayan_Secret_Hotel_Boutique-Chetumal_Yucatan_Peninsula.html?m=19905"
          icon={<FaTripadvisor />}
        />
      </Stack>
    </Stack>
  )
}

export default SocialLinks

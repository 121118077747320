import React from 'react'
import PropTypes from 'prop-types'
import { SimpleGrid, Box, Heading, Stack, Flex } from '@chakra-ui/react'

import { LIS_GAP, COL_GAP, P } from '../globals'

export function FeatureItem({ title, desc, image, icon, button, ...restProps }) {
  return (
    <Stack direction="column" width="100%" spacing={LIS_GAP} {...restProps}>
      {image && (
        <Box
          borderRadius="15px"
          position="relative"
          paddingBottom="66%"
          overflow="hidden"
          marginBottom={1}
        >
          {image}
        </Box>
      )}
      {icon && <Box>{icon}</Box>}
      <Stack spacing={LIS_GAP}>
        <Heading as="h4" fontWeight="bold" fontSize="xl">
          {title}
        </Heading>
        {desc && typeof desc === 'string' ? <P>{desc}</P> : desc}
        {button && (
          <Flex alignItems="flex-end" height="100%" width="100%">
            {button}
          </Flex>
        )}
      </Stack>
    </Stack>
  )
}

FeatureItem.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.node,
  image: PropTypes.node,
  icon: PropTypes.node,
  button: PropTypes.node,
}

FeatureItem.defaultProps = {
  title: '',
  desc: null,
  image: null,
  icon: null,
  button: null,
}

export function FeatureList({ children, ...restProps }) {
  return (
    <SimpleGrid
      display={{ base: 'flex', md: 'grid' }}
      flexDirection={{ base: 'column', md: 'row' }}
      columns={{ base: 1, md: 2, lg: children.length }}
      spacing={COL_GAP}
      {...restProps}
    >
      {children}
    </SimpleGrid>
  )
}

FeatureList.propTypes = {
  children: PropTypes.node,
}

FeatureList.defaultProps = {
  children: null,
}
